.KPIContainer {
  position: absolute;
  z-index: 999;
  bottom: 26px;
  right: 60px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
}

.Card {
  color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 20px 20px 20px;
  height: 142px;
  border-radius: 15px;
}

.Card p {
  font-size: 0.95em;
  font-weight: bold;
  text-align: left;
}

.Card:nth-child(1) {
  background-color: #db930e;
}

.Card:nth-child(2) {
  background-color: #5ca310;
}

.Card:nth-child(3) {
  background-color: #e10e23;
}

.Card:nth-child(4) {
  background-color: #e3280b;
  /* padding: 10px 15px 10px 15px;
  width: 250px; */

}

.CardInfo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5px;
  /* padding: 6px 0 0 0 ; */
}
.CardInfo:nth-child(4) {
  padding: 0 ;
}

.CardInfo h1 {
  font-size: 2em;
  /* margin-top: -13px; */
  font-weight: bolder;
}

.ListCardsHeader {
  display: flex;
  flex-direction: row;
}

.ListCards {
  width: 300px;
  cursor: pointer;
  border-radius: 0px;
  padding: 20px 5px 10px 20px;
  color: #aaa;
  border-bottom: 1px solid rgb(121, 121, 121);
}

.ListCard-briefs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5px;
}

.icons-show {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 5px;
  margin: 5px 10px 0 0;
}

.icons-show p {
  margin-top: -1px;
  margin-left: 5px;
  font-size: 0.8em;
  font-weight: bold;
}

.KPIToggle {
  position: absolute;
  right: 10px;
  bottom: 94px;
  z-index: 9999;
  cursor: pointer;
  height: 34px;
  width: 34px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(0,0,0,0.2);
}

.DetailCard {
  background-color: #333;
  height: 380px;
  width: 320px;
  top: 80px;
  position: absolute;
  z-index: 9999;
  right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  right: 7px;
  top: 5px;
  cursor: pointer;
}

.DetailCard {
  padding: 20px;
  color: #fff;
}

.DetailCard-main div {
  display: flex;
  flex-direction: row;
}

.CardDetail-briefs {
  margin-top: 25px;
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
}

.Detailicons-show p {
  margin: 0;
  padding-left: 10px;
}

.FilterContainer {
  background-color: #333;
  position: absolute;
  top: 80px;
  right: 10px;
  z-index: 9999;
  width: 320px;
  padding: 15px 10px 10px 10px;
  font-size: 0.9em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.AnimatedFilter{
  background-color: #333;
  width: 320px;
  padding: 15px 10px 10px 10px;
  font-size: 0.9em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.rightPanel{
  position: absolute;
  top: 80px;
  right: 10px;
  z-index: 9999;
  width: 320px;
}
